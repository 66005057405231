(function (root, factory) {
        if (typeof define === 'function' && define.amd) {
            // AMD. Register as an anonymous module.
            define([], factory);
        } else if (typeof module === 'object' && module.exports) {
            // Node. Does not work with strict CommonJS, but
            // only CommonJS-like environments that support module.exports,
            // like Node.
            module.exports = factory();
        } else {
            // Browser globals (root is window)
            root.returnExports = factory();
        }
    }(typeof self !== 'undefined' ? self : this, function () {
        // Just return a value to define the module export.
        // This example returns an object, but the module
        // can return a function as the exported value.
        'use strict';
        var skins = {};
         skins['wysiwyg.viewer.skins.LoginSocialBarSkin'] = {
  "react": [
    [
      "button",
      "login",
      [],
      {}
    ],
    [
      "nav",
      "buttons",
      [],
      {}
    ],
    [
      "div",
      "userWrapper",
      [],
      {},
      [
        "button",
        "user",
        [],
        {},
        [
          "div",
          "icon",
          [],
          {}
        ],
        [
          "div",
          "text",
          [],
          {}
        ],
        [
          "div",
          "arrow",
          [],
          {},
          [
            "svg",
            null,
            [],
            {
              "xmlns": "http://www.w3.org/2000/svg",
              "width": "14",
              "height": "8",
              "viewBox": "0 0 14 8"
            },
            [
              "path",
              null,
              [],
              {
                "fillRule": "nonzero",
                "d": "M1.707.293L.293 1.707l6 6a1 1 0 0 0 1.397.016l6-5.726L12.31.55 7.016 5.602 1.707.292z"
              }
            ]
          ]
        ]
      ],
      [
        "select",
        "dropdownMenuMobile",
        [],
        {}
      ]
    ],
    [
      "nav",
      "dropdownMenu",
      [],
      {}
    ]
  ],
  "exports": {
    "button": {
      "skin": "wysiwyg.viewer.skins.LoginSocialButtonSkin"
    },
    "avatar": {
      "skin": "wysiwyg.viewer.skins.IconSkin"
    }
  },
  "params": {
    "bg": "BG_COLOR_ALPHA",
    "brw": "BORDER_SIZE",
    "brd": "BORDER_COLOR_ALPHA",
    "rd": "BORDER_RADIUS",
    "shd": "BOX_SHADOW",
    "bg-dd": "BG_COLOR_ALPHA",
    "brw-dd": "BORDER_SIZE",
    "brd-dd": "BORDER_COLOR_ALPHA",
    "rd-dd": "BORDER_RADIUS",
    "shd-dd": "BOX_SHADOW",
    "fnt": "FONT",
    "fnt-size-dd": "TEXT_SIZE",
    "txt-dd": "TEXT_COLOR",
    "txt-slct-dd": "TEXT_COLOR",
    "txth-dd": "TEXT_COLOR",
    "txth": "TEXT_COLOR",
    "txt": "TEXT_COLOR"
  },
  "paramsDefaults": {
    "bg": "transparent",
    "brw": "0",
    "brd": "color_15",
    "rd": "0px",
    "shd": "0 0 0 rgba(0, 0, 0, 0)",
    "bg-dd": "color_11",
    "brw-dd": "1px",
    "brd-dd": "color_15",
    "rd-dd": "0px",
    "shd-dd": "0 0 0 rgba(0, 0, 0, 0)",
    "fnt": "font_8",
    "fnt-size-dd": "15px",
    "txt-dd": "color_15",
    "txt-slct-dd": "color_18",
    "txth-dd": "color_14",
    "txth": "color_17",
    "txt": "color_18"
  },
  "css": {
    "%": "display:-webkit-box;display:-webkit-flex;display:flex;-webkit-box-align:center;-webkit-align-items:center;align-items:center;box-sizing:border-box;pointer-events:none;",
    "%[data-state~=\"loggedIn\"]": "background-color:[bg];border:[brw] solid [brd];[rd]  [shd]",
    "%[data-state~=\"loggedIn\"] %login": "display:none;",
    "%[data-state~=\"loggedIn\"] %dropdownMenuMobile": "pointer-events:all;position:absolute;opacity:0;cursor:pointer;width:100%;height:100%;left:0;",
    "%[data-state~=\"loggedIn\"] %dropdownMenu": "z-index:99999;background-color:[bg-dd];border:[brw-dd] solid [brd-dd];box-sizing:border-box;[rd-dd]  [shd-dd]  [fnt]  font-size:[fnt-size-dd];position:absolute;min-width:100px;max-width:300px;",
    "%[data-state~=\"loggedIn\"] %dropdownMenu a": "color:[txt-dd];padding-left:20px;padding-right:20px;display:-webkit-box;display:-webkit-flex;display:flex;line-height:260%;[rd-dd]    pointer-events:all;",
    "%[data-state~=\"loggedIn\"] %dropdownMenu a[data-state~=\"selected\"],%[data-state~=\"loggedIn\"] %dropdownMenu a[data-preview~=\"selected\"]": "color:[txt-slct-dd];",
    "%[data-state~=\"loggedIn\"] %dropdownMenu a:hover,%[data-state~=\"loggedIn\"] %dropdownMenu a[data-preview~=\"hover\"]": "color:[txth-dd];",
    "%[data-state~=\"loggedIn\"] %dropdownMenu a > div:first-child": "overflow:hidden;text-overflow:ellipsis;white-space:nowrap;",
    "%[data-state~=\"loggedIn\"] %dropdownMenu a > div:nth-child(2)": "opacity:0.6;",
    "%[data-state~=\"loggedIn\"] %dropdownMenu hr": "margin:5px 20px 5px 20px;opacity:0.4;",
    "%[data-state~=\"loggedIn\"] %user": "cursor:pointer;pointer-events:all;white-space:nowrap;padding-top:6px;padding-bottom:6px;position:relative;",
    "%[data-state~=\"loggedIn\"] %user:hover %text,%[data-state~=\"loggedIn\"] %user[data-preview~=\"hover\"] %text": "color:[txth];",
    "%[data-state~=\"loggedIn\"] %user:hover %arrow path,%[data-state~=\"loggedIn\"] %user[data-preview~=\"hover\"] %arrow path": "fill:[txth];",
    "%[data-state~=\"loggedIn\"] %icon": "display:inline-block;vertical-align:middle;",
    "%[data-state~=\"loggedIn\"] %icon img": "border-radius:50%;",
    "%[data-state~=\"loggedIn\"] %text": "color:[txt];[fnt]  display:inline-block;vertical-align:middle;text-overflow:ellipsis;overflow:hidden;white-space:nowrap;min-width:60px;",
    "%[data-state~=\"loggedIn\"] %arrow": "display:inline-block;vertical-align:middle;padding-left:14px;padding-right:14px;",
    "%[data-state~=\"loggedIn\"] %arrow path": "fill:[txt];",
    "%[data-state~=\"loggedIn\"] %buttons": "padding-top:6px;padding-bottom:6px;white-space:nowrap;",
    "%[data-state~=\"ltr\"] %icon": "padding-right:14px;padding-left:initial;",
    "%[data-state~=\"ltr\"] %buttons": "padding-left:14px;padding-right:initial;",
    "%[data-state~=\"ltr\"] %login": "padding-right:14px;padding-left:initial;",
    "%[data-state~=\"ltr\"][data-state~=\"avatarOnly\"] %arrow": "padding-right:14px;padding-left:initial;",
    "%[data-state~=\"ltr\"][data-state~=\"noButtons\"] %icon": "padding-left:14px;",
    "%[data-state~=\"ltr\"][data-state~=\"noButtons\"][data-state~=\"textOnly\"] %text": "padding-left:14px;padding-right:initial;",
    "%[data-state~=\"ltr\"][data-state~=\"textOnly\"] %login": "padding-left:14px;",
    "%[data-state~=\"rtl\"] %icon": "padding-right:initial;padding-left:14px;",
    "%[data-state~=\"rtl\"] %buttons": "padding-left:initial;padding-right:14px;",
    "%[data-state~=\"rtl\"] %login": "padding-right:initial;padding-left:14px;",
    "%[data-state~=\"rtl\"][data-state~=\"avatarOnly\"] %arrow": "padding-right:initial;padding-left:14px;",
    "%[data-state~=\"rtl\"][data-state~=\"noButtons\"] %icon": "padding-right:14px;",
    "%[data-state~=\"rtl\"][data-state~=\"noButtons\"][data-state~=\"textOnly\"] %text": "padding-right:14px;padding-left:initial;",
    "%[data-state~=\"rtl\"][data-state~=\"textOnly\"] %login": "padding-right:14px;",
    "%[data-state~=\"left\"] %dropdownMenu a > div:nth-child(2)": "padding-left:12px;padding-right:initial;",
    "%[data-state~=\"right\"] %dropdownMenu a > div:nth-child(2)": "padding-left:initial;padding-right:12px;",
    "%[data-state~=\"loggedOut\"]": "background-color:[bg];border:[brw] solid [brd];[rd]  [shd]",
    "%[data-state~=\"loggedOut\"] %user,%[data-state~=\"loggedOut\"] %dropdownMenu,%[data-state~=\"loggedOut\"] %buttons,%[data-state~=\"loggedOut\"] %dropdownMenuMobile": "display:none;",
    "%[data-state~=\"loggedOut\"] %login": "white-space:nowrap;padding-top:6px;padding-bottom:6px;color:[txt];[fnt]  [rd]  cursor:pointer;pointer-events:all;",
    "%[data-state~=\"loggedOut\"] %login:hover,%[data-state~=\"loggedOut\"] %login[data-preview~=\"hover\"]": "color:[txth];",
    "%[data-state~=\"loggedOut\"] %login span": "vertical-align:middle;",
    "%[data-state~=\"loggedOut\"] %login %avatar": "display:inline-block;vertical-align:middle;padding-right:14px;padding-left:14px;"
  }
}
 skins['wysiwyg.viewer.skins.LoginSocialButtonSkin'] = {
  "react": [
    [
      "a",
      "link",
      [],
      {},
      [
        "div",
        "icon",
        [],
        {}
      ],
      [
        "div",
        "text",
        [],
        {}
      ],
      [
        "div",
        "badge",
        [],
        {}
      ]
    ]
  ],
  "exports": {
    "icon": {
      "skin": "wysiwyg.viewer.skins.IconSkin"
    }
  },
  "params": {
    "badge-bg": "COLOR",
    "badge-txt": "TEXT_COLOR"
  },
  "paramsDefaults": {
    "badge-bg": "#E21C21",
    "badge-txt": "color_11"
  },
  "css": {
    "%": "position:relative;display:inline-block;",
    "%icon": "border-radius:50%;",
    "%badge": "background-color:[badge-bg];color:[badge-txt];border-radius:10px;position:absolute;top:0;text-align:center;height:18px;line-height:18px;letter-spacing:1px;",
    "%badge span": "padding-left:6px;padding-right:6px;",
    "% a": "display:block;pointer-events:all;"
  }
}

        return skins;
    }));