/* eslint-disable santa/no-module-state */
define(['santa-components', 'prop-types', 'lodash', 'componentsCore', 'coreUtils', 'icon'], function (santaComponents, PropTypes, _, componentsCore, coreUtils, icon) {
    'use strict';

    const linkRenderer = coreUtils.linkRenderer;

    /**
     * @class components.loginButton
     * @extends {core.skinBasedComp}
     */
    return {
        displayName: 'loginSocialButton',
        mixins: [componentsCore.mixins.skinBasedComp, componentsCore.mixins.createChildComponentMixin],

        propTypes: _.assign({
            id: PropTypes.string,
            label: PropTypes.string,
            iconRef: PropTypes.object,
            link: PropTypes.object,
            displayCount: PropTypes.number,
            iconSize: PropTypes.number,
            buttonsDirection: PropTypes.string,
            svgProps: PropTypes.object,
            rootNavigationInfo: santaComponents.santaTypesDefinitions.Component.rootNavigationInfo.isRequired
        },
        santaComponents.utils.santaTypesUtils.getSantaTypesByDefinition(icon)
        ),

        onButtonClick() {
            if (this.props.onClick) {
                this.props.onClick();
            }
        },

        createBadge() {
            let badge = null;
            if (_.isNumber(this.props.displayCount)) {
                const countToDisplay = this.props.displayCount >= 1000 ? `${Math.floor(this.props.displayCount / 1000)}k` : this.props.displayCount;

                badge = santaComponents.utils.createReactElement('span', {
                    children: countToDisplay
                });
            }
            return badge;
        },

        getAriaLabel() {
            let title = '';
            if (_.isNumber(this.props.displayCount)) {
                title = `${this.props.displayCount} `;
            }
            title += this.props.label;
            return title;
        },

        createIcon() {
            if (this.props.iconRef) {
                return this.createChildComponent(this.props.iconRef, 'wysiwyg.viewer.components.Icon', 'icon', {
                    ref: 'icon',
                    id: `${this.props.id}icon`,
                    iconSize: this.props.iconSize,
                    link: null,
                    svgProps: this.props.svgProps,
                    style: {
                        width: this.props.iconSize,
                        height: this.props.iconSize
                    }
                });
            }
        },

        getSkinProperties() {
            const iconSize = this.props.iconSize / 2;
            const badgeStyle = this.props.buttonsDirection === 'rtl' ? {right: iconSize, left: 'auto'} : {left: iconSize, right: 'auto'};

            return {
                '': {
                    onClick: this.onButtonClick,
                    'aria-label': this.getAriaLabel()
                },
                'link': this.props.link ? linkRenderer.renderLink(this.props.link, this.props.linkRenderInfo, this.props.rootNavigationInfo) : {tabIndex: 0},
                'icon': this.createIcon(),
                'badge': {
                    children: this.createBadge(),
                    style: badgeStyle
                }
            };
        },

        getDefaultSkinName() {
            return 'wysiwyg.viewer.skins.LoginSocialButtonSkin';
        }
    };
});
