define([
    'lodash',
    'componentsCore',
    'coreUtils'
], function (_, componentsCore, coreUtils) {
    'use strict';

    const DEFAULT_AVATAR_ID = '3d84bae5ad4d4d8a96de15e9f4b79a08.svg';

    function createFetchRequest(siteData, svgId) {
        return coreUtils.svg.createSvgFetchRequest(
            siteData.serviceTopology.mediaRootUrl,
            siteData,
            svgId);
    }

    function checkRequirements(compInfoPath, compType, siteData, compInfo) {
        const result = [createFetchRequest(siteData, DEFAULT_AVATAR_ID)];

        const iconItems = _.get(compInfo, compInfoPath);
        _.forEach(iconItems, function (iconItem) {
            const svgId = _.get(iconItem, 'iconRef.svgId');
            if (svgId) {
                result.push(createFetchRequest(siteData, svgId));
            }
        });

        const requiredLang = _.get(compInfo, ['data', 'language']);
        result.push(coreUtils.translationsLoader.getRequest(siteData, requiredLang, {initiator: compType}));
        return _.compact(result);
    }

    _.map({
        'wysiwyg.viewer.components.LoginSocialBar': 'data.iconItemsRef.menuRef.items',
        'wysiwyg.viewer.components.mobile.TinyMenu': 'data.loginSocialBarRef.iconItemsRef.menuRef.items'
    }, (compInfoPath, compType) => componentsCore.dataRequirementsCheckerRegistrar.registerCheckerForCompType(compType, checkRequirements.bind(null, compInfoPath, compType)));

    return {
        DEFAULT_AVATAR_ID
    };
});
