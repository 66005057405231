define([
    'lodash',
    'loginSocialBar/components/loginSocialBar',
    'loginSocialBar/components/loginSocialButton',
    'componentsCore',
    'skins',
    'loginSocialBar/skins/skins.json',
    'loginSocialBar/components/loginSocialBarDataRequirementsChecker'
], function (_,
             loginSocialBar,
             loginSocialButton,
             componentsCore,
             skinsPackage,
             skinsJson,
             loginSocialBarDataRequirementsChecker) {
    'use strict';

    const result = {
        loginSocialBar,
        loginSocialButton,
        loginSocialBarDataRequirementsChecker
    };

    componentsCore.compRegistrar
        .register('wysiwyg.viewer.components.LoginSocialBar', result.loginSocialBar)
        .register('wysiwyg.viewer.components.LoginSocialButton', result.loginSocialButton);

    skinsPackage.skinsMap.addBatch(skinsJson);

    return result;
});
