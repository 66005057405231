/* eslint-disable santa/no-module-state */
define([
    'santa-components',
    'lodash',
    'coreUtils',
    'textCommon',
    'reactDOM',
    'zepto',
    'loginSocialBar/components/loginSocialButton',
    'icon',
    'componentsCore'
], function (
    santaComponents,
    _,
    coreUtils,
    textCommon,
    reactDOM,
    $,
    loginSocialButton,
    icon,
    componentsCore) {
    'use strict';

    const linkRenderer = coreUtils.linkRenderer;

    const LOGGED_IN_MEMBER_TYPES = {
        AVATAR: 'avatarOnly',
        TEXT: 'textOnly',
        BOTH: 'avatarAndText'
    };
    const PREVIEW_STATES = {
        LOGGED_IN: 'loggedIn',
        LOGGED_IN_MENU_OPENED: 'loggedInMenuOpened',
        LOGGED_OUT: 'loggedOut'
    };

    const DEFAULT_SPACING = 10;

    const OPEN_MENU_KEYS = {
        32: 'Space',
        13: 'Enter',
        40: 'ArrowDown'
    };

    function getDropdownCss(rootElement, arrowNode, dropdownNode, isRTL, isMobileView = false) { // eslint-disable-line complexity
        const clientRect = rootElement.getBoundingClientRect();

        const compBorderWidth = parseInt($(rootElement).css('border-width'), 10) || 0;
        const dropdownWidth = dropdownNode.offsetWidth;
        const compHeight = clientRect.height;
        const topLayout = {
            top: `${compHeight - compBorderWidth}px`
        };
        let customLayout;

        if (arrowNode.children.length > 0 && dropdownWidth > 0) {
            const siteParent = isMobileView ? $('#SITE_ROOT')[0] : $('#SITE_CONTAINER')[0];
            const siteParentRect = siteParent.getBoundingClientRect();
            const arrowBoundingRect = arrowNode.children[0].getBoundingClientRect();
            let rightPosition = clientRect.right - arrowBoundingRect.right - compBorderWidth;
            let leftPosition = arrowBoundingRect.left - clientRect.left - compBorderWidth;

            if (!isRTL) {
                const offscreenLeft = siteParentRect.left - clientRect.right + rightPosition + dropdownWidth;
                if (offscreenLeft > 0) {
                    rightPosition -= offscreenLeft;
                }
                customLayout = {
                    right: `${rightPosition}px`,
                    left: 'initial'
                };
            } else {
                const offscreenRight = clientRect.left + leftPosition + dropdownWidth - siteParentRect.right;
                if (offscreenRight > 0) {
                    leftPosition -= offscreenRight;
                }
                customLayout = {
                    left: `${leftPosition}px`,
                    right: 'initial'
                };
            }
        }

        return _.assign(topLayout, customLayout || {});
    }

    function shouldShowAvatar(compData) {
        return compData.loggedInMember === LOGGED_IN_MEMBER_TYPES.AVATAR || compData.loggedInMember === LOGGED_IN_MEMBER_TYPES.BOTH;
    }

    function shouldShowUserName(compData) {
        return compData.loggedInMember === LOGGED_IN_MEMBER_TYPES.TEXT || compData.loggedInMember === LOGGED_IN_MEMBER_TYPES.BOTH;
    }

    function hasButtons(props) {
        return _.isArray(props.iconItems) && props.iconItems.length > 0;
    }

    function getStateFromProps(props) {
        return {
            isLoggedIn: props.isLoggedIn,
            $loggedIn: props.isLoggedIn ? 'loggedIn' : 'loggedOut',
            $direction: props.compProp.buttonsDirection === 'rtl' ? 'rtl' : 'ltr',
            $dropdownDirection: props.compProp.dropDownTextAlignment === 'right' ? 'right' : 'left',
            $iconsOnly: props.compData.loggedInMember,
            $noButtons: hasButtons(props) ? 'buttons' : 'noButtons'
        };
    }

    function getDropDownItemTextAlignment(props) {
        switch (props.compProp.dropDownTextAlignment) {
            case 'left':
                return props.compProp.buttonsDirection === 'rtl' ? 'row-reverse' : 'row';
            case 'right':
                return props.compProp.buttonsDirection === 'rtl' ? 'row' : 'row-reverse';
            default:
                return 'row';
        }
    }

    function getComponentAlignment(props) {
        switch (props.compProp.buttonsAlignment) {
            case 'left':
                return props.compProp.buttonsDirection === 'rtl' ? 'flex-end' : 'flex-start';
            case 'right':
                return props.compProp.buttonsDirection === 'rtl' ? 'flex-start' : 'flex-end';
            case 'center':
                return 'center';
            default:
                return 'flex-end';
        }
    }

    /**
     * @class components.loginSocialBar
     * @extends {core.skinBasedComp}
     */
    return {
        displayName: 'loginSocialBar',
        mixins: [componentsCore.mixins.skinBasedComp, textCommon.textScaleMixin, componentsCore.mixins.createChildComponentMixin],

        propTypes: _.assign(
            {
                compData: santaComponents.santaTypesDefinitions.Component.compData.isRequired,
                compProp: santaComponents.santaTypesDefinitions.Component.compProp.isRequired,
                isMobileView: santaComponents.santaTypesDefinitions.isMobileView,
                isMobileDevice: santaComponents.santaTypesDefinitions.Device.isMobileDevice,
                isTabletDevice: santaComponents.santaTypesDefinitions.Device.isTabletDevice,
                rootNavigationInfo: santaComponents.santaTypesDefinitions.Component.rootNavigationInfo.isRequired,
                navigateToRenderedLink: santaComponents.santaTypesDefinitions.Navigation.navigateToRenderedLink.isRequired,
                currentUrlPageId: santaComponents.santaTypesDefinitions.Component.currentUrlPageId,
                linkRenderInfo: santaComponents.santaTypesDefinitions.Link.renderInfo.isRequired,
                componentPreviewState: santaComponents.santaTypesDefinitions.RenderFlags.componentPreviewState,
                componentViewMode: santaComponents.santaTypesDefinitions.RenderFlags.componentViewMode,
                logout: santaComponents.santaTypesDefinitions.SiteMembersSantaTypes.logout.isRequired,
                showAuthenticationDialog: santaComponents.santaTypesDefinitions.SiteMembersSantaTypes.showAuthenticationDialog.isRequired,
                serviceTopology: santaComponents.santaTypesDefinitions.ServiceTopology.serviceTopology.isRequired,
                isLoggedIn: santaComponents.santaTypesDefinitions.MemberLogin.isLoggedIn.isRequired,
                language: santaComponents.santaTypesDefinitions.MemberLogin.language.isRequired,
                memberName: santaComponents.santaTypesDefinitions.MemberLogin.memberName.isRequired,
                memberAvatar: santaComponents.santaTypesDefinitions.MemberLogin.memberAvatar,
                memberDefaultAvatar: santaComponents.santaTypesDefinitions.MemberLogin.memberDefaultAvatar,
                menuItems: santaComponents.santaTypesDefinitions.MemberLogin.menuItems,
                iconItems: santaComponents.santaTypesDefinitions.MemberLogin.iconItems,
                isResponsive: santaComponents.santaTypesDefinitions.RendererModel.isResponsive,
                svgPropsForMemberLoginIconItems: santaComponents.santaTypesDefinitions.VectorImage.svgPropsForMemberLoginIconItems,
                isExperimentOpen: santaComponents.santaTypesDefinitions.isExperimentOpen.isRequired
            },
            santaComponents.utils.santaTypesUtils.getSantaTypesByDefinition(loginSocialButton),
            santaComponents.utils.santaTypesUtils.getSantaTypesByDefinition(icon)
        ),

        getInitialState() {
            return _.assign(getStateFromProps(this.props), {isMenuOpen: false});
        },

        createLoginSocialButtonComponent(buttonProps, index) {
            let svgProps = null;
            const svgId = _.get(buttonProps, 'iconRef.svgId');
            if (svgId) {
                svgProps = _.get(this.props.svgPropsForMemberLoginIconItems, svgId, null);
            }

            return this.createChildComponent(this.props.compData, 'wysiwyg.viewer.components.LoginSocialButton', 'button',
                _.assign(_.clone(buttonProps), {
                    ref: `button${index}`,
                    id: `${this.props.id}button${index}`,
                    style: {
                        width: this.props.compProp.iconSize,
                        height: this.props.compProp.iconSize,
                        marginRight: this.props.compProp.buttonsDirection === 'rtl' ? 'auto' : DEFAULT_SPACING,
                        marginLeft: this.props.compProp.buttonsDirection === 'rtl' ? DEFAULT_SPACING : 'auto'
                    },
                    linkRenderInfo: this.props.linkRenderInfo,
                    rootNavigationInfo: this.props.rootNavigationInfo,
                    iconSize: this.props.compProp.iconSize,
                    buttonsDirection: this.props.compProp.buttonsDirection,
                    svgProps
                })
            );
        },

        getButtonsToRender() {
            if (hasButtons(this.props)) {
                return _.map(this.props.iconItems, this.createLoginSocialButtonComponent);
            }
            return null;
        },

        toggleMenu(openMenu) {
            this.registerReLayout();
            this.setState({
                isMenuOpen: openMenu || !this.state.isMenuOpen
            });
        },

        onUserClick() {
            this.toggleMenu();
        },

        onDropdownMenuItemKeyDown(event) { // eslint-disable-line complexity
            let tabbableElements;
            const currentlyFocusedElement = event.target;
            if (event.key === 'ArrowDown' || event.key === 'Tab' || event.key === 'ArrowUp') {
                event.stopPropagation();
                event.preventDefault();

                let nextIndex = 0;
                tabbableElements = componentsCore.utils.accessibility.getTabbaleElements(reactDOM.findDOMNode(this.refs.dropdownMenu));
                const currentlyFocusedIndex = tabbableElements.indexOf(currentlyFocusedElement);

                if (currentlyFocusedIndex > -1) {
                    if (event.key === 'ArrowUp') {
                        nextIndex = currentlyFocusedIndex - 1;
                    } else {
                        nextIndex = currentlyFocusedIndex + 1;
                    }
                }
                if (nextIndex >= tabbableElements.length) {
                    nextIndex = 0;
                } else if (nextIndex < 0) {
                    nextIndex = tabbableElements.length - 1;
                }

                const elementToFocus = tabbableElements[nextIndex];
                if (elementToFocus) {
                    $(elementToFocus).focus();
                }
            } else if (event.key === 'Enter') {
                event.stopPropagation();
                event.preventDefault();

                _.invoke(currentlyFocusedElement, 'click');
            } else if (event.key === 'Escape') {
                event.stopPropagation();
                event.preventDefault();

                this.toggleMenu(false);

                const user = reactDOM.findDOMNode(this.refs.user);
                if (user) {
                    user.focus();
                }
            }
        },

        onUserKeyDown(event) { // eslint-disable-line complexity
            if (this.state.isMenuOpen && (event.key === 'Enter' || event.key === 'Escape' || event.key === 'ArrowUp' || event.keyCode === 32)) {
                event.stopPropagation();
                event.preventDefault();

                this.toggleMenu(false);
                return;
            }

            if (OPEN_MENU_KEYS[event.keyCode]) {
                event.stopPropagation();
                event.preventDefault();

                if (this.state.isMenuOpen) { //arrow down
                    const tabbableElements = componentsCore.utils.accessibility.getTabbaleElements(reactDOM.findDOMNode(this.refs.dropdownMenu));
                    if (_.size(tabbableElements) > 0) {
                        _.head(tabbableElements).focus();
                    }
                } else {
                    this.toggleMenu(true);
                }
            }
        },

        onBlur(event) {
            if (this.state.isMenuOpen) {
                if (!event.relatedTarget && _.includes(_.get(window, 'document.activeElement.id', ''), this.props.id)) { //IE fallback
                    return;
                }
                if (!event.relatedTarget || !_.includes(event.relatedTarget.id, this.props.id)) { //outer click
                    this.toggleMenu(false);
                }
            }
        },

        onLogout(event) {
            if (this.getLogoutFunction) {
                const logout = this.getLogoutFunction(event);
                if (_.isFunction(logout)) {
                    logout.call(this);
                }
            } else {
                this.setState({isMenuOpen: false});
                this.props.logout(this.props.language);
            }
        },

        onLogin(event) {
            if (this.getLoginFunction) {
                const login = this.getLoginFunction(event);
                if (_.isFunction(login)) {
                    login.call(this);
                }
            } else {
                this.setState({isMenuOpen: false});
                this.props.showAuthenticationDialog({
                    language: this.props.language,
                    appId: 3
                });
            }
        },

        getMenuItems() {
            const menuItems = _.concat(this.props.menuItems || [], {
                label: this.props.compData.logOutText || 'Log Out',
                onClick: this.onLogout
            });

            const flexDirection = getDropDownItemTextAlignment(this.props);
            const lastItemBeforeLogoutIndex = menuItems.length - 2;
            const logoutIndex = menuItems.length - 1;

            return _.map(menuItems, function (menuItem, index) { // eslint-disable-line complexity
                let extraProps = {};
                if (menuItem.link) {
                    extraProps = linkRenderer.renderLink(menuItem.link, this.props.linkRenderInfo, this.props.rootNavigationInfo);
                } else {
                    extraProps.tabIndex = 0;
                }
                if (index === 0) {
                    extraProps['data-preview'] = this.props.componentPreviewState;
                }
                if (menuItem.isSelected) {
                    extraProps['data-state'] = 'selected';
                }

                return santaComponents.utils.createReactElement('li', {
                    key: `item${index}`,
                    children: [santaComponents.utils.createReactElement('a', _.assign({
                        children: [
                            santaComponents.utils.createReactElement('div', {
                                children: menuItem.label,
                                id: `${this.props.id}menuItemDiv${index}`,
                                key: `${this.props.id}menuItemDiv${index}`

                            }),
                            _.isNumber(menuItem.displayCount) ?
                                santaComponents.utils.createReactElement('div', {
                                    key: `${this.props.id}displayCount${index}`,
                                    children: `(${menuItem.displayCount})`
                                }) : null
                        ],
                        ref: `menuItem${index}`,
                        id: `${this.props.id}menuItem${index}`,
                        key: `${this.props.id}menuItem${index}`,
                        'data-aid': `menuItem${index}`,
                        style: {
                            flexDirection,
                            marginTop: index === 0 ? 10 : 'initial',
                            marginBottom: index === logoutIndex ? 10 : 'initial'
                        },
                        onKeyDown: this.onDropdownMenuItemKeyDown
                    }, extraProps)), index === lastItemBeforeLogoutIndex ? santaComponents.utils.createReactElement('hr', {key: `separator${index}`}) : null],
                    onClick: menuItem.onClick || null
                });
            }.bind(this));
        },

        getMenuOptions() {
            return [
                {label: 'Choose an option', value: -1}, // TODO: liorar - this should be translated with the key "Mobile_Menu_Members_Options_NoSelection" when problems with santa-langs are resolved.
                ..._.map(this.props.menuItems, (menuItem, index) => ({label: menuItem.label, value: index, link: menuItem.link, onClick: menuItem.onClick, isSelected: menuItem.isSelected})),
                {label: this.props.compData.logOutText || 'Log Out', onClick: this.onLogout, value: (_.get(this.props.menuItems, 'length') || 0) + 1}
            ];
        },

        onSelectionChange(e) {
            const index = _.parseInt(e.target.selectedIndex);
            const menuItem = this.getMenuOptions()[index];
            if (menuItem.link) {
                const link = linkRenderer.renderLink(menuItem.link, this.props.linkRenderInfo, this.props.rootNavigationInfo);
                this.props.navigateToRenderedLink(link);
            } else if (_.isFunction(menuItem.onClick)) {
                menuItem.onClick(e);
            }
        },

        componentWillReceiveProps(nextProps) { // eslint-disable-line complexity
            const state = getStateFromProps(nextProps);

            if (!_.isEqual(this.props.componentPreviewState, nextProps.componentPreviewState)) {
                if (_.includes(nextProps.componentPreviewState, PREVIEW_STATES.LOGGED_IN_MENU_OPENED)) {
                    state.isMenuOpen = true;
                } else if (!nextProps.componentPreviewState || _.includes(nextProps.componentPreviewState, PREVIEW_STATES.LOGGED_IN) ||
                    _.includes(nextProps.componentPreviewState, PREVIEW_STATES.LOGGED_OUT)) {
                    state.isMenuOpen = false;
                }
            } else if (!_.isEqual(this.props.componentViewMode, nextProps.componentViewMode)) {
                state.isMenuOpen = false;
            } else if (!_.isEqual(this.props.currentUrlPageId, nextProps.currentUrlPageId)) {
                state.isMenuOpen = false;
            }

            if (!_.isMatch(this.state, state)) {
                this.setState(state);
            }
        },

        componentDidUpdate(prevProps, prevState) {
            if (this.props.isResponsive && this.state.isMenuOpen && !prevState.isMenuOpen) {
                $(this.refs.dropdownMenu).css(getDropdownCss(this.refs[''], this.refs.arrow, this.refs.dropdownMenu, this.props.compProp.buttonsDirection === 'rtl', false));
            }
        },

        renderMemberAvatar() {
            let compData;
            let svgProps = null;

            if (this.props.memberAvatar) {
                compData = {
                    uri: this.props.memberAvatar,
                    type: 'Image'
                };
            } else {
                compData = {
                    svgId: this.props.memberDefaultAvatar,
                    type: 'VectorImage'
                };
                svgProps = _.get(this.props.svgPropsForMemberLoginIconItems, this.props.memberDefaultAvatar, null);
            }

            return this.createChildComponent(compData, 'wysiwyg.viewer.components.Icon', 'avatar', {
                ref: 'avatar',
                id: `${this.props.id}avatar`,
                iconSize: this.props.compProp.iconSize,
                style: {
                    width: this.props.compProp.iconSize,
                    height: this.props.compProp.iconSize
                },
                svgProps
            });
        },

        getSkinProperties() { // eslint-disable-line complexity
            const refData = {
                '': {
                    style: {
                        direction: this.props.compProp.buttonsDirection || 'ltr',
                        justifyContent: getComponentAlignment(this.props)
                    },
                    'aria-label': 'Members bar',
                    'data-icon-size': this.props.compProp.iconSize,
                    'data-is-logged-in': this.state.isLoggedIn,
                    onBlur: this.onBlur
                }
            };

            let menuOptions = [];
            let selectedOption;
            if (this.props.isMobileView) {
                const optionsData = this.getMenuOptions();
                menuOptions = _.map(optionsData, option => santaComponents.utils.createReactElement('option', _.assign(option, {children: [option.label]})));
                selectedOption = menuOptions[_.findIndex(optionsData, 'isSelected')] || menuOptions[0];
            }

            const fontSize = _.get(this.getFontSize(), 'fontSize', null);

            if (this.state.isLoggedIn) {
                _.assign(refData, {
                    'buttons': {
                        children: this.getButtonsToRender(),
                        style: {
                            display: !hasButtons(this.props) ? 'none' : null
                        }
                    },
                    'userWrapper': {
                        style: {
                            position: 'relative'
                        }
                    },
                    'user': {
                        onClick: this.onUserClick,
                        onKeyDown: this.onUserKeyDown,
                        'data-preview': this.props.componentPreviewState,
                        'aria-label': `${this.props.memberName} account menu`
                    },
                    'icon': {
                        children: this.renderMemberAvatar(),
                        style: {
                            display: !shouldShowAvatar(this.props.compData) ? 'none' : null
                        }
                    },
                    'text': {
                        children: santaComponents.utils.createReactElement('span', {children: this.props.memberName}),
                        style: {
                            display: !shouldShowUserName(this.props.compData) ? 'none' : null,
                            fontSize
                        }
                    },
                    'dropdownMenu': {
                        children: santaComponents.utils.createReactElement('ul', {
                            children: this.getMenuItems()
                        }),
                        style: {
                            display: !this.props.isMobileView && this.state.isMenuOpen ? null : 'none',
                            fontSize
                        },
                        'aria-live': 'polite'
                    },
                    'dropdownMenuMobile': {
                        style: {
                            display: this.props.isMobileView ? 'auto' : 'none',
                            fontSize
                        },
                        children: menuOptions,
                        value: _.get(selectedOption, 'props.value'),
                        onChange: this.onSelectionChange
                    }
                });
            } else {
                _.assign(refData, {
                    'login': {
                        children: [
                            shouldShowAvatar(this.props.compData) ? this.renderMemberAvatar() : null,
                            santaComponents.utils.createReactElement('span', {children: this.props.compData.loggedOutText || 'Login | Sign up'})
                        ],
                        onClick: this.onLogin,
                        style: {
                            fontSize
                        },
                        'data-preview': this.props.componentPreviewState
                    }
                });

                refData['']['aria-label'] = 'Login or Sign up';
            }

            return refData;
        },

        getDefaultSkinName() {
            return 'wysiwyg.viewer.skins.LoginSocialBarSkin';
        }
    };
});
